<template>
  <div class='box'>
    <van-icon name="certificate"/>
    请选择登录类型
  </div>
  <div class="choice">
    
    <van-cell-group title="选择商户登录">
      <van-cell icon="shop-o" center @click="clickMerchant()" style="padding:1.5rem" is-link :title="merchant.name" :label="merchant.code" />
    </van-cell-group>
    <van-cell-group title="选择门店登录">
      <van-cell icon="desktop-o" center @click="clickStore(item)" v-for="item in stores" is-link :key = 'item' style="padding:1.5rem" :title="item.name" :label="item.code" />
    </van-cell-group>
</div>
  
</template>

<script>
import { storeLst , logins } from '@/api/login'

	export default {
		data() {
      
			return {
        stores:[],
        merchant:{},
			}
    },
    // created(){
    //   this.$router.go(0)
    // },
   
    mounted: function() {
            if (location.href.indexOf("#reloaded") == -1) {
                location.href = location.href + "#reloaded";
                location.reload();
            }
      this.getList()

        },
		methods: {
      getList(){
        storeLst({page:1,limit:100}).then((res) => {
          console.log(res)
          this.stores = res.data.data
          this.merchant = res.data.merchant
        })
      },
      clickMerchant(){
        logins().then((res) => {
          this.$store.commit('SET_TOKEN', res.data.token)
          this.$store.commit('SET_IS_LOGIN', true)
          if (this.$route.query.redirect) window.location.href = this.$route.query.redirect
          else window.location.href = '/statistics'
        })
        
      },
      clickStore(val){
        logins({sid:val.id}).then((res) => {
          this.$store.commit('SET_TOKEN', res.data.token)
          this.$store.commit('SET_IS_LOGIN', true)
          if (this.$route.query.redirect) window.location.href = this.$route.query.redirect
          else window.location.href = '/'
        })
     }
		},
		
	}
</script>

<style lang="less" scoped>
.box{
  line-height: 7rem;
  font-size: 16px;
  padding-left: 1.4rem;
  color: #323233;
  background: #fff;
}
.choice{
	min-height: calc(100vh);
}
</style>
